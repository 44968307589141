import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const FeatureCard = ({ title, description, img, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className="bg-gradient-to-br from-gray-800 to-gray-900 border border-gray-700 flex flex-col p-6 sm:p-8 text-center rounded-xl shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-105"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={inView ? { scale: 1, opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.2 }}
        className="mb-6 flex items-center justify-center h-48 sm:h-64"
      >
        <img src={img} alt={title} className="text-[#7c3aed] w-auto h-full mx-auto object-contain" />
      </motion.div>
      <motion.h3
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.4 }}
        className="text-xl sm:text-2xl font-bold mb-3 text-white"
      >
        {title}
      </motion.h3>
      <motion.p
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.6 }}
        className="text-gray-300 text-sm sm:text-base"
      >
        {description}
      </motion.p>
    </motion.div>
  );
};

const Features = () => {
  const features = [
    {
      title: 'Automated Utility Bill Uploads',
      description: 'Quickly upload utility bills for automatic analysis and insights.',
      img: '/automated.svg', 
    },
    {
      title: 'Carbon Emission Monitoring',
      description: 'Monitor your building\'s emissions and stay compliant.',
      img: '/monitoring.svg',
    },
    {
      title: 'Compliance Forecasting',
      description: 'Forecast compliance status and avoid costly penalties.',
      img: '/forecast.svg',
    },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      id="features"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-white py-16 sm:py-24"
    >
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.div
          variants={itemVariants}
          className="text-[#7c3aed] text-base sm:text-lg font-semibold mb-3 uppercase tracking-wide"
        >
          Features
        </motion.div>
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-12 sm:mb-16">
          <motion.h2
            variants={itemVariants}
            className="text-3xl sm:text-4xl lg:text-5xl leading-tight font-bold mb-4 lg:mb-0 lg:mr-4 lg:max-w-2xl"
          >
            Powerful Features to Simplify Energy Compliance
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-gray-300 text-base sm:text-lg max-w-xl lg:max-w-md"
          >
            Designed to streamline energy compliance, Cosmos
            equips you with the tools to track, manage, and report
            emissions effortlessly.
          </motion.p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} index={index} />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Features;