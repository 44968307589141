import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Hero = () => {
  const handleFeaturesClick = (event) => {
    event.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="text-white py-16 md:py-24 lg:py-32 relative z-10 overflow-hidden">
      <motion.div 
        className="absolute inset-0 opacity-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1.5 }}
      ></motion.div>
      <div className="container mx-auto text-center px-4 relative z-20">
        <motion.h1 
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 font-poppins leading-tight bg-gradient-to-r from-white via-purple-300 to-blue-300 bg-clip-text text-transparent"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Automate Energy Compliance<br className="hidden sm:block" />and Cut Emissions
        </motion.h1>
        <motion.p 
          className="text-xl md:text-2xl mb-10 max-w-3xl mx-auto font-medium text-gray-300 leading-relaxed"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          Stay ahead of changing energy laws with Cosmos' powerful, 
          automated solution. Save time, reduce emissions, and avoid fines.
        </motion.p>
        <motion.div 
          className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6 mb-16 mt-10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link to="https://calendly.com/brian-correa-cosmos/30-minute-call" className="bg-[#7c3aed] text-white px-8 py-4 font-semibold text-lg rounded-full hover:bg-[#6d28d9] transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 inline-block">
              Request a Demo
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <button onClick={handleFeaturesClick} className="bg-transparent border-2 border-[#7c3aed] text-[#7c3aed] px-8 py-4 rounded-full text-lg font-semibold hover:bg-[#7c3aed] hover:text-white transition-all duration-300 inline-block">
              Explore Features
            </button>
          </motion.div>
        </motion.div>
        <motion.div 
          className="max-w-6xl mx-auto relative"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <div className="rounded-lg p-1 aspect-video relative group">
            <motion.div 
              className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-500 opacity-75 blur-md group-hover:opacity-100 transition-all duration-300 rounded-lg"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            ></motion.div>
            <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg">
              <rect className="animate-electric-border" x="0" y="0" width="100%" height="100%" fill="none" stroke="url(#electric-gradient)" strokeWidth="2" rx="8" ry="8" />
              <defs>
                <linearGradient id="electric-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#7c3aed">
                    <animate attributeName="offset" values="0;1;0" dur="4s" repeatCount="indefinite" />
                  </stop>
                  <stop offset="100%" stopColor="#3b82f6">
                    <animate attributeName="offset" values="0;1;0" dur="4s" repeatCount="indefinite" />
                  </stop>
                </linearGradient>
              </defs>
            </svg>
            <motion.div 
              className="relative bg-black rounded-lg overflow-hidden shadow-2xl"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <img
                src="../steam_graph.png"
                alt="Cosmos Dashboard"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/10 to-transparent"></div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;