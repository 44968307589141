import React from 'react';
import { motion } from 'framer-motion';

const partnerLogos = [
  { src: "/triplelift.svg", name: 'Triplelift' },
  { src: "/meta.svg", name: 'Meta' },
  { src: "/transmit.svg", name: 'Transmit' },
  { src: "/kustomer.svg", name: 'Kustomer' },
]

const LeadershipCard = ({ name, role, history, imageSrc }) => (
  <motion.div 
    className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-4 sm:p-6 flex flex-col items-center shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <img src={imageSrc} alt={name} className="w-24 h-24 sm:w-32 sm:h-32 rounded-full mb-4 object-cover" />
    <h3 className="text-lg sm:text-xl font-bold text-white mb-1">{name}</h3>
    <p className="text-purple-400 text-sm sm:text-base">{role}</p>
    <p className="text-sm sm:text-md text-center mt-2">{history}</p>
  </motion.div>
);

const LogoScroller = ({ logos }) => (
  <div className="flex flex-wrap justify-center items-center gap-8 mt-8 sm:mt-16">
    {logos.map((logo, index) => (
      <img key={index} src={logo.src} title={logo.name} alt={logo.name} className="h-8 sm:h-12 w-auto object-contain" />
    ))}
  </div>
);

const Company = () => {
  const leadershipTeam = [
    { name: "Brian Correa", role: "CEO", imageSrc: "/brian.jpg", history: "Previously: Tripleift, Transmit" },
    { name: "Katrina Rodriguez", role: "CTO", imageSrc: "/katrina.jpeg", history: "Previously: Meta, Kustomer" }
  ];

  return (
    <section className="company bg-cosmos-dark text-white py-12 sm:py-16 md:py-24">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.h1 
          className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 sm:mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img 
              src={`/cosmos-logo-purple.svg`}
              alt={`Logo`}
              className="w-32 md:w-60 h-auto mx-auto object-contain"
            />
        </motion.h1>
        
        <motion.p 
          className="text-base sm:text-lg text-gray-300 mb-8 sm:mb-12 max-w-2xl mx-auto text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          The team behind Cosmos has over 10 years of experience building complex software with strict reliability and privacy requirements. Now we're bringing our expertise to the physical world to help buildings reduce their carbon footprint.
        </motion.p>

        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8 mb-12 sm:mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {leadershipTeam.map((leader, index) => (
            <LeadershipCard key={index} {...leader} />
          ))}
        </motion.div>

        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Built by engineers from</h2>
          <LogoScroller logos={partnerLogos} />
        </motion.div>
      </div>
    </section>
  );
};

export default Company;