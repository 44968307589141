import React from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const RequestDemo = () => {
  const handleFeaturesClick = (event) => {
    event.preventDefault();
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const controls = useAnimation();

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: [0.6, -0.05, 0.01, 0.99] },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-white py-24 relative overflow-hidden"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1.5 }}
        className="absolute inset-0"
      ></motion.div>
      <div className="container mx-auto text-center px-4 relative z-10">
        <div className="max-w-4xl mx-auto">
          <motion.h2
            variants={itemVariants}
            className="text-5xl font-bold leading-tight font-poppins mb-6 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent"
          >
            Ready to Simplify Your Energy Compliance?
          </motion.h2>
          <motion.p
            variants={itemVariants}
            className="text-xl mb-10 text-gray-300 leading-relaxed"
          >
            Save time, reduce emissions, and avoid fines. Request your free demo
            today to learn how Cosmos can streamline your approach to energy compliance.
          </motion.p>
          <motion.div
            variants={itemVariants}
            className="flex justify-center items-center space-x-6"
          >
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link to="https://calendly.com/brian-correa-cosmos/30-minute-call" className="bg-[#7c3aed] text-white px-8 py-4 font-semibold text-lg rounded-full hover:bg-[#6d28d9] transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1 inline-block">
                Request a Demo
              </Link>
            </motion.div>
            <motion.a
              onClick={handleFeaturesClick}
              className="text-gray-300 hover:text-white transition-colors duration-300 underline"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Learn More
            </motion.a>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
        className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-[#7c3aed] via-[#3b82f6] to-[#10b981] origin-left"
      ></motion.div>
    </motion.section>
  );
};

export default RequestDemo;