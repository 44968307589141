import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const FooterLink = ({ to, children }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <Link to={to} className="text-gray-400 hover:text-white transition-colors duration-300">
      {children}
    </Link>
  </motion.div>
);

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 border-[#444444] border-t">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <motion.div 
            className="flex space-x-6 mb-4 md:mb-0"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <FooterLink to="https://www.linkedin.com/company/golivecosmos">
              <img className="h-6 w-6" src={'/linkedin.svg'} />
            </FooterLink>
            <FooterLink to="https://twitter.com/golivecosmos">
              <img className="h-6 w-6" src={'/x_logo.svg'} />
            </FooterLink>
          </motion.div>
          
          <motion.div 
            className="text-sm text-center md:text-right text-gray-400"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            Copyright © {new Date().getFullYear()} Cosmos Technologies, Inc, All rights reserved
          </motion.div>
        </div>
        <motion.div 
          className="flex flex-wrap justify-center md:justify-between items-center gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <div className="flex flex-wrap justify-center gap-4">
            <FooterLink to="/terms">Terms & conditions</FooterLink>
            <FooterLink to="/privacy">Privacy policy</FooterLink>
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            <FooterLink to="/contact">Contact</FooterLink>
            {/* <FooterLink to="/community">Community</FooterLink> */}
          </div>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;