import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CosmosInfoBanner = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-white py-24 overflow-hidden relative"
    >
      <div className="container mx-auto text-center px-4 relative z-10">
        <motion.h2
          variants={itemVariants}
          className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#7c3aed] to-[#3b82f6] bg-clip-text text-transparent"
        >
          Simplifying Building Decarbonization Compliance
        </motion.h2>
        <motion.p
          variants={itemVariants}
          className="text-xl mb-16 max-w-3xl mx-auto font-medium leading-relaxed text-gray-300"
        >
          Cosmos navigates the complex world of building decarbonization laws for you. 
          With our platform you track emissions, uncover reduction opportunities, and stay ahead of the latest regulations.
        </motion.p>
        <motion.div
          variants={itemVariants}
          className="relative mb-8"
        >
          <motion.div
            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gradient-to-r from-[#7c3aed] to-[#3b82f6] text-white"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Analyzing utility bills from
          </motion.div>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="relative before:content-[''] before:absolute before:left-0 before:top-0 before:w-32 before:h-full before:bg-gradient-to-r from-[#1a1a2e] to-transparent before:z-10 after:content-[''] after:absolute after:right-0 after:top-0 after:w-32 after:h-full after:bg-gradient-to-l from-[#1a1a2e] to-transparent after:z-10"
        >
          <div className="flex animate-scroll space-x-20">
            {['conedison.webp', 'national_grid.webp', 'pseg_white.png', 'conedison.webp', 'national_grid.webp', 'pseg_white.png'].map((logo, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-48 h-24 flex-shrink-0 rounded-lg flex items-center justify-center overflow-hidden bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm transition-all duration-300 hover:bg-opacity-20"
              >
                <img 
                  src={`/${logo}`}
                  alt={`Logo ${logo}`}
                  className="max-w-full max-h-full object-contain p-4"
                />
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

const styles = `
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 30s linear infinite;
}
`;

export default () => (
  <>
    <style>{styles}</style>
    <CosmosInfoBanner />
  </>
);