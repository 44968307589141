import React, { useEffect } from 'react';

const Stars = () => {
  useEffect(() => {
    const createStars = () => {
      const starsContainer = document.getElementById('stars');
      const count = 100;

      for (let i = 0; i < count; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        star.style.animationDuration = `${Math.random() * 3 + 2}s`; // Random duration between 2-5s
        star.style.animationDelay = `${Math.random() * 2}s`; // Random delay up to 2s
        starsContainer.appendChild(star);
      }
    };

    createStars();
  }, []);

  return <div id="stars" className="fixed inset-0 pointer-events-none z-0" />;
};

export default Stars;